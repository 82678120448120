/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Ya sabéis que de vez en cuando me gusta ver cómo aparatos electrónicos se destruyen mediante diversas técnicas, ahí va una muestra, un vídeo de un televisor de plasma sometido a 2000 V: [youtube=http://www.youtube.com/watch?v=mkvQ9aWM0DE&w=580]");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
